import React from 'react';
import styled from 'styled-components';
import {Alert, message} from 'antd';
import * as AppActions from '../../AppActions';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as SdkConfig from 'rev.sdk.js/Config';
import * as ApiUtil from 'rev.sdk.js/Utils/ApiUtil';
import qs from 'query-string';
import GenericForm from 'rev.sdk.js/Generic/Form';
import projectSchema from '../../../content/admin/project.json';
import {useOutlet} from 'reconnect.js';

export default function ProjectEditorPage(props) {
  const [project, setProject] = React.useState(null);
  const [token, setToken] = React.useState(null);
  const {name, refresh} = qs.parse(props.location.search);
  const [loading] = useOutlet('loading');

  React.useEffect(() => {
    async function fetchData() {
      AppActions.setLoading(true);
      try {
        const resp = await ApiUtil.req(
          `${SdkConfig.get().authHost}/jwt/access?refresh_token=${refresh}`,
        );
        setToken(resp.token);
        setProject(
          await JStorage.fetchOneDocument('project', {name}, null, {
            token: resp.token,
          }),
        );
      } catch (ex) {
        console.warn('ProjectEditorPage ex', ex);
        message.error('取得資料失敗');
      }
      AppActions.setLoading(false);
    }

    fetchData();
  }, [name, refresh]);

  const uiSchema = React.useMemo(() => {
    const {uiSchema} = projectSchema.formSpec;
    uiSchema.name = {
      'ui:disabled': true,
    };
    [
      's3Bucket',
      'cloudfrontDistributionId',
      'branch',
      'frontend_branch',
      'is_frontend_mkt_deploy',
      'backend_branch',
      'webHost',
      'allowAnonymousCheckout',
      'allowGithubRebuild',
      'allowReferBy',
      'gaId',
      'gtmId',
      'pixelId',
      'canFacebookLogin',
      'canLineLogin',
      'canGoogleLogin',
      'canNormalLogin',
      'paymentSubTypes',
      'logisticsTypes',
      'logisticsSubTypes',
      'invoiceCategories',
      'invoiceTypes',
      'invoiceCarrierTypes',
      'codegen',
      'backendExtras',
      'frontendCodegen',
      'frontendExtras',
    ].forEach((key) => {
      uiSchema[key] = {
        'ui:widget': 'hidden',
      };
    });
    return uiSchema;
  }, []);

  if (!name || !refresh) {
    return (
      <Wrapper>
        <div className="content">
          <Alert type="error" message="連結失效，請再次取得連結。" showIcon />
        </div>
      </Wrapper>
    );
  }

  if (!project && !loading) {
    return (
      <Wrapper>
        <div className="content">
          <Alert type="error" message="找不到資源，請與HiShop聯絡。" showIcon />
        </div>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <h1>編輯專案</h1>
      <div className="content">
        <GenericForm
          schema={projectSchema.formSpec.schema}
          uiSchema={uiSchema}
          instance={project}
          onSubmit={async (formData, extValues) => {
            AppActions.setLoading(true);
            try {
              await JStorage.updateDocument('project', {name}, formData, {
                token,
              });
              message.success('編輯成功!');
            } catch (ex) {
              console.warn(ex);
              message.error('發生錯誤, 無法進行編輯！');
            }
            AppActions.setLoading(false);
          }}
        />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: #eee;
  padding: 20px;
  padding-top: var(--topNavBarHeight);
  min-height: var(--contentMinHeight);

  & > h1 {
    text-align: center;
  }

  & > .content {
    max-width: 720px;
    margin: 20px auto;
    border-radius: 16px;
    padding: 20px;
    background-color: white;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  }
`;
